* {
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

.home-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 25px;
  color: rgba(1, 1, 1, 0.7);
  align-items: center;
}

.type-writer {
  padding: 50px;
  margin: 0 auto;
  font-weight: 300;
  color: rgba(1, 1, 1, 0.8);
  text-align: center;
}

.photo {
  border-radius: 5px;
  margin: 50px;
  width: 70%;
}

.navbar {
  background-color: rgba(1, 1, 1, 0.7);
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: rgba(1, 1, 1, 0.7);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start; 
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: white;
  font-size: 18px;
  width: 95%;
  height: 100%; 
  display: flex;
  align-items: center; 
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: rgba(1, 1, 1, 0.8);
}

.nav-menu-items {
  width: 100%; 
}

.navbar-toggle {
  width: 100%; 
  height: 80px;
  display: flex; 
  justify-content: start; 
  align-items: center;
}

span {
  margin-left: 10px;
}

.footer {
  color: rgba(1, 1, 1, 0.7);
  position: fixed; 
  left: 0;
  bottom: 0;
  padding: 20px;
  width: 100%;
  font-size: 13px;
  text-align: center;
}

.techstack {
  display: flex;
  padding: 20px;
  font-size: 50px;
  color: rgba(1, 1, 1, 0.7);
  width: 100%;
  justify-content: space-around;
}

.about-heading {
  color: rgba(1, 1, 1, 0.7);
  padding: 50px 50px 10px 50px;
  font-weight: 300;
  font-size: 30px;
}

.about-para {
  color: rgba(1, 1, 1, 0.9);
  padding: 20px 50px 50px 50px;
  font-size: 20px;
  font-weight: 300;
}

.project-heading {
  color: rgba(1, 1, 1, 0.7);
  padding: 50px 50px 10px 50px;
  font-weight: 300;
  font-size: 25px;
}

.project-title {
  color: rgba(1, 1, 1, 0.7);
  padding: 10px 50px 0 50px;
  font-weight: 300;
  font-size: 30px;
}

.project-link {
  text-decoration: none;
  color: rgba(1, 1, 1, 0.8);
  font-weight: 400;
}

.project-p {
  color: rgba(1, 1, 1, 0.9);
  padding: 10px 50px 10px 50px;
  font-size: 20px;
  font-weight: 300;
}

.contact-h2 {
  display: block;
  font-weight: 300;
  padding: 50px;
}

.contact-links {
  color: rgba(1, 1, 1, 0.7);
  padding: 50px;
  display: flex;
  justify-content: space-around;
}

.contact-links a {
  text-decoration: none;
  color: rgba(1, 1, 1, 0.7);
  font-size: 50px;
}

.slides .slick-prev,
.slides .slick-next {
  position: absolute;
  top: 50%;
  z-index: 1;
}

.slider-div {
  width: 40%;
  margin: 0 auto;
}

.slides {
  position: relative;
}

.slides .slick-prev,
.slides .slick-next {
  position: absolute;
  top: 50%;
  z-index: 1;
}

.slides .slick-prev {
  left: 5%;
}

.slides .slick-next {
  right: 5%;
}

.slider-imgs {
  width: 100%;
  border-radius: 5px;
}